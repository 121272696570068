import * as React from "react"
import { graphql } from 'gatsby'
import { Col, Container, Row, Modal } from 'react-bootstrap'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import StickyBar from "../components/Campaign/StickyBar/StickyBar";
import Banner from "../components/Campaign/Banner/banner";
import BannerVideo from "../components/Campaign/Banner/bannerVideo";
import ContactFormFields from "../../static/forms/contact_form.json"
//import EventModule from "../components/Campaign/EventModule/EventModule";
import SEO from "../components/seo";
//import '../scss/custom.scss';
//import RegisterYourIntrest from "../components/Campaign/RegisterYourIntrest/RegisterYourIntrest";

const GalleryModule = loadable(() => import("../components/Campaign/GalleryModule/GalleryModule"));
const AmenitiesModule = loadable(() => import("../components/Campaign/AmenitiesModule/AmenitiesModule"));
const BrochureModule = loadable(() => import("../components/Campaign/BrochureModule/BrochureModule"));
const BrochureForm = loadable(() => import("../components/Campaign/BrochureForm/BrochureForm"));
const UnitTypesModule = loadable(() => import("../components/Campaign/Banner/UnitTypesModule"));


const DefaultForm = loadable(() => import("../components/forms/default-form-layout"))

const CampaignTemplate = ({ data }) => {
  
  const campaign = data?.strapiCampaign  
  const siteData = data?.allStrapiSiteConfig
  //console.log("campaign", campaign)
 // const siteData = data?.strapiSiteConfig
  const [renderComponent, setRenderComponent] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  }, [])
  return (
    <Layout data={campaign} siteData={siteData}>      
      {/* <!-- Google Tag Manager (noscript) --> */}
   
      { <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KS2MFKHV"
        height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript> }

      {/* <!-- End Google Tag Manager (noscript) --> */}

      <div className={`campaign ${campaign?.Custom_CSS_Class}`}>
        {campaign?.Banner_Type && campaign?.Banner_Type === "video" ?
          <BannerVideo {...campaign} /> : <Banner {...campaign} />
        }       
       
       <div className='form-wrapper register-your-interest-form'>
            <Container>
              <Row>
                <Col xl={12}>
                  <div className='form-wrapper'>
                    <h2>Register your Interest</h2>  
                    <DefaultForm fields={ContactFormFields} classname="enquiry-form-wrapper" />
                  </div>
                </Col>
              </Row>
            </Container>
        </div>

        <StickyBar {...campaign} />
        {campaign?.Add_Campaign_Module && campaign?.Add_Campaign_Module.map((module, k) => {
          return (           
              <>
              {module.type === "STRAPI__COMPONENT_MOD_COLLECTION_BLOCK" &&

                <GalleryModule {...module} />
              }
              {module.type === "STRAPI__COMPONENT_MOD_KEY_FEATURES" &&
              <AmenitiesModule {...module} />
              }
              {module.type === "STRAPI__COMPONENT_MOD_IMAGE_BLOCK" &&
                <BrochureModule {...module} />
              }            
              {module.type === "STRAPI__COMPONENT_CAMPAIGN_MODULES_BLOCK" &&
                <BlockModule {...module} />
              }   
              {module.type === "STRAPI__COMPONENT_MOD_COLLECTION_FORM" && module.form_type === "download_your_brochure" &&
                <BrochureForm {...module} />
              }            
              </>          
          )
        })} 
          
        {/*campaign.Show_Event &&
          <EventModule {...campaign} />
       */ }

        <UnitTypesModule />

      </div >
    </Layout >
  )
}

export const Head = ({ data }, props) => {
  const campaign = data?.strapiCampaign;
  const ogImageUrl = "https://ggfx-pearlgates3.s3.eu-west-2.amazonaws.com/i/IMG_9125_84bdc30d8f.jpg";

  //const siteData = data?.strapiSiteConfig

  /*var schemaSameAs = ''
  if (siteData?.facebook_link.length > 1) {
    schemaSameAs = siteData?.facebook_link + ','
  }
  if (siteData?.twitter_link.length > 1) {
    schemaSameAs += siteData?.twitter_link + ','
  }
  if (siteData?.instagram_link.length > 1) {
    schemaSameAs += siteData?.instagram_link + ','
  }
  if (siteData?.linkedin_link.length > 1) {
    schemaSameAs += siteData?.linkedin_link + ','
  }

  var ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": process.env.GATSBY_SITE_URL,
    "name": process.env.GATSBY_SITE_NAME,
    "alternateName": process.env.GATSBY_SITE_NAME,
    "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`,
    "sameAs": schemaSameAs.replace(/,\s*$/, "").split(',')
  };*/

  return (
    <SEO title={campaign?.Meta_Title} description={campaign?.Meta_Description} ogImageUrl={ogImageUrl}>
     {
     /* <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      />*/
      }
    </SEO>
  )
}

export default CampaignTemplate;

export const query = graphql`
  query ($campaign_id: Int) {
    strapiCampaign(strapi_id: {eq: $campaign_id}) {
      ...CampaignFragment
      Add_Campaign_Module {
        ... on STRAPI__COMPONENT_MOD_COLLECTION_BLOCK {
          ...CampaignCollectionBlockFragment
        }
        ... on STRAPI__COMPONENT_MOD_KEY_FEATURES {
          ...CampaignKeyFeaturesFragment
        }
        ... on STRAPI__COMPONENT_MOD_IMAGE_BLOCK {
          ...CampaignImageBlockFragment
        }
        ... on STRAPI__COMPONENT_MOD_COLLECTION_FORM {
          ...CampaignCollectionFormFragment
        }
      }  
    }      
    
   allStrapiSiteConfig {
    edges {
      node {
        Qatar_Address
        Qatar_Facebook_Link
        Footer_Content
        Qatar_Instagram_Link
        Qatar_Linkedin_Link
        Qatar_Youtube_Link
      }
    }
  }
    
  }
`
